import React, { Component } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner';
import { deleteNews, getCurrentUser, getVideos, postVideo } from '../services/http/authService';

export default class Home extends Component {
  racesRef = React.createRef();

  arr = ['Intervjui', 'Joniziranje', 'Novak', 'Vijesti', 'Šetnje', 'Polemike'];

  state = {
    inputText: '',
    category: 'Odaberite',
    videos: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.getAllVideos();
  }

  getAllVideos = () => {
    getVideos().then(this.onGetVideosSuccess).catch(this.onGetVideosError);
  };

  onGetVideosError = (err) => {
    if (err && err.message) {
      if (err.message.includes('No news') || err.message.includes('Failed to fetch')) {
        return;
      }
    }
    alert(err.message);
  };

  onGetVideosSuccess = (res) => {
    console.log(res.result);
    const user = getCurrentUser();
    this.setState({ videos: res.result, user });
  };

  onDeleteNews = (newsId) => {
    if (window.confirm('Jeste li sigurni da zelite obrisati post sa ID: ' + newsId)) {
      this.setState({ isLoading: true });
      deleteNews(newsId).then(this.onDeleteNewsSuccess).catch(this.onDeleteNewsError);
    }
  };

  onDeleteNewsError = (err) => {
    this.setState({ isLoading: false, error: err.message + ' - molimo kontaktirajte administratora' });
  };

  onDeleteNewsSuccess = (res) => {
    alert('Post uspjesno obrisan');
    this.setState({ isLoading: false, error: '' });
  };

  getIndex = () => {
    const index = this.arr.findIndex((item) => item == this.state.category);
    return index;
  };

  onSendClick = async (e) => {
    let { inputText } = this.state;
    window.scrollTo(0, 0);
    const ind = this.getIndex();
    if (ind == -1) {
      alert('Molimo odaberite ispravnu kategoriju');
      return;
    }
    this.setState({ isLoading: true });
    postVideo({
      youtubeLink: inputText,
      videoCategoryId: ind + 1,
    })
      .then((res) => {
        this.onVideoSuccess(res.result);
      })
      .catch((err) => {
        this.onVideoFailed(err.message);
      });
  };

  onVideoSuccess = (res) => {
    if ('statusCode' in res && res.statusCode != 200) {
      throw res;
    }
    alert('Video uspjesno dodan');
    this.setState({ isLoading: false, error: '', inputText: '' });
    // this.getAllVideos();
  };

  onVideoFailed = (err) => {
    console.log(err);
    this.setState({ isLoading: false, error: err, inputText: '' });
  };

  handleInputChange = (e) => {
    this.setState({ inputText: e.target.value });
  };

  handleSelect = (e) => {
    this.setState({ category: e });
  };

  render() {
    const { volunteers, news } = this.state;
    return (
      <div className="main-cont">
        {this.state.isLoading == true ? (
          <div className="text-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
            <LoadingSpinner height={100} width={100} text="Dodavanje videa u toku. Molimo nemojte zatvarati ovaj prozor..." />
          </div>
        ) : (
          <main id="main" ref={this.racesRef}>
            <section id="featured" class="featured">
              <div class="container">
                <div class="section-title" data-aos="fade-up">
                  <h2>Postavljanje videa</h2>
                </div>
                {this.state.error && <div className="error-text big">{'Greška: ' + this.state.error}</div>}
                <div class="row">
                  <div class="col-lg-3"> </div>
                  <div class="col-lg-6">
                    <div class="icon-box fivek">
                      <div class="text-block text-center">{/* <h2>Bosanski jezik</h2> */}</div>
                      <label for="fname">Unesite link youtube videa</label>
                      <br></br>
                      <input type="text" id="fname" name="fname" style={{ width: '100%' }} onChange={this.handleInputChange} />
                      <br></br>
                      <br></br>
                      <label>Odaberite kategoriju</label>
                      <DropdownButton
                        id="dropdown-variants-secondary"
                        key="secondary"
                        title="secondary"
                        variant="secondary"
                        title={this.state.category}
                        onSelect={this.handleSelect}
                      >
                        <Dropdown.Item eventKey="Intervjui">Intervjui</Dropdown.Item>
                        <Dropdown.Item eventKey="Joniziranje">Joniziranje</Dropdown.Item>
                        <Dropdown.Item eventKey="Novak">Novak</Dropdown.Item>
                        <Dropdown.Item eventKey="Vijesti">Vijesti</Dropdown.Item>
                        <Dropdown.Item eventKey="Šetnje">Šetnje</Dropdown.Item>
                        <Dropdown.Item eventKey="Polemike">Polemike</Dropdown.Item>
                      </DropdownButton>
                      <div class="text-center">
                        <button class="btn btn-sm box-btn" onClick={this.onSendClick}>
                          Pošalji
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3"> </div>
                </div>
              </div>
            </section>

            {/* <section id="featured" class="featured">
              <div class="container">
                <div class="section-title" data-aos="fade-up">
                  <h2>Sve vijesti</h2>
                </div>
                <table border="1">
                  <tr>
                    <th
                      onClick={(e) => {
                        this.sortNews(e, 'id');
                      }}
                    >
                      Id&#9662;
                    </th>
                    <th
                      onClick={(e) => {
                        this.sortNews(e, 'title');
                      }}
                    >
                      Naslov&#9662;
                    </th>
                    <th
                      onClick={(e) => {
                        this.sortNews(e, 'content');
                      }}
                    >
                      Tekst&#9662;
                    </th>
                    <th>Park Ravne 2</th>
                    <th>Visit</th>
                    <th>Brisanje</th>
                  </tr>
                  {this.mapNews(news)}
                </table>
              </div>
            </section> */}
          </main>
        )}

        {/* <Footer></Footer> */}
      </div>
    );
  }
}
