import { CountryRegionData } from "react-country-region-selector";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React, { Component } from 'react';
import { REGION_LABEL } from "../../constants/form";

export default class Dropdown extends Component {
  state = {
    country: ""
  }
  props = {}
  getRegions = country => {
    if (!country) {
      return [];
    }
    return country[2].split("|").map(regionPair => {
      let [regionName, regionShortCode = null] = regionPair.split("~");
      return regionName;
    });
  };

  change = (val) => {
    let country = "";
    if (Array.isArray(val.value.target.value)) {
      country = val.value.target.value[0];
    }
    else country = val.value.target.value;
    const currentTarget = { name: val.name, value: country }
    this.props.onChange({ currentTarget });
    this.setState({ country: val.value.target.value })
  }

  changeRegion = (val) => {
    const region = val.value.target.value;
    const currentTarget = { name: val.name, value: region }
    this.props.onChange({ currentTarget });
  }

  render() {
    const props = this.props;
    return (
      <div className="dropdown">
        <TextField
          id="country"
          label={props.country || props.label}
          name={props.name}
          select
          variant="outlined"
          required={props.required}
          fullWidth
          type="text"
          onChange={(val) => this.change({ name: props.name, value: val })}
        >
          {CountryRegionData.map((option, index) => (
            <MenuItem key={option[0]} value={option}>
              {option[0]}
            </MenuItem>
          ))}
        </TextField>

        {props.showRegion == true && this.state.country != "" && <div className="region-select"><TextField
          id="region"
          label={REGION_LABEL}
          select
          name="region"
          fullWidth
          variant="outlined"
          required={props.required}
          onChange={(val) => this.changeRegion({ name: "region", value: val })}
        >
          {this.getRegions(this.state.country).map(
            (option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )
          )}
        </TextField></div>}
      </div>

    )

  }


}

