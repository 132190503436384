import fetch from 'node-fetch';
import { getJwt } from './authService';

function validateStatus(result) {
  const { status, message } = result;
  if (status) {
    throw new Error(message);
  }
}

function resolveResult(res, headers) {
  const result = JSON.parse(res);
  validateStatus(result);
  return {
    result,
    headers,
  };
}

async function handleResponse(res) {
  const { headers } = res;
  res = await res.text();
  if (res.includes('ECONNREFUSED')) throw new Error('Could not connect to server');
  return resolveResult(res, headers);
}

/**
 * GET request
 *
 * @param {String} path Path to get
 */
export function get(path) {
  const token = '';
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'x-auth': token },
  };
  return fetch(path, request).then(async (res) => {
    return await handleResponse(res);
  });
}

/**
 * POST request
 *
 * @param {String} path Path to post
 * @param {Object} data Data to post
 * @param {Object} query Query params
 */
export function post(path, data, query = {}, includeJwt = false) {
  let request = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (includeJwt) {
    const jwt = getJwt();
    if (jwt != null) {
      request['headers']['x-auth'] = getJwt();
    }
  }
  return fetch(path, request).then(async (res) => {
    return await handleResponse(res);
  });
}

/**
 * DELETE request
 *
 * @param {String} path Path to delete
 * @param {Object} data Data containing headers
 * @param {Object} query Query params
 */
export function deleteCall(path, data, query = {}) {
  const request = {
    method: 'DELETE',
    headers: data.headers,
  };
  return fetch(path, request).then(async (res) => {
    if (res.status !== 200) {
      return await resolveResult(res);
    }
    return res;
  });
}

export function put(path, data = {}) {
  const token = '';
  const request = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-auth': token,
    },
    body: JSON.stringify(data),
  };
  return fetch(path, request).then(async (res) => {
    return await handleResponse(res);
  });
}
