import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@material-ui/core';

const onCheckboxChecked = (val, name, onChange, optionalHandler) => {
  const value = val.target.checked;
  if (optionalHandler) optionalHandler({ name, value });
  return onChange({ currentTarget: { name, value } });
}

const CustomBox = withStyles({
  root: {
    color: "gray",
    '&$checked': {
      color: "#ea4c89",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} size="small" />);

const renderCheckBoxes = (values, onChange, name, optionalHandler) => {
  return values.map(value =>
    <FormControlLabel
      value={value} control={<CustomBox onChange={(e) => onCheckboxChecked(e, name, onChange, optionalHandler)} />} label={value} />
  )
}

const Checkboxes = ({
  type,
  label,
  className,
  values,
  validate,
  buttonPressed,
  onChange,
  name,
  optionalHandler,
  ...rest
}) => {
  return (
    <div className="checkbox-container">
      {/* {label && <FormLabel className="radio-label" component="legend">{label}*</FormLabel>} */}
      <FormGroup row className="checkbox-buttons" name={name}>
        {renderCheckBoxes(values, onChange, name, optionalHandler)}
      </FormGroup>
    </div>
  );
};

export default Checkboxes;