import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import React from "react";

const CustomRadio = withStyles({
  root: {
    color: "gray",
    '&$checked': {
      color: "#ea4c89",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} size="small" />);

const renderRadios = (values) => {
  return values.map(value =>
    <FormControlLabel
      value={value} control={<CustomRadio />} label={value} />
  )
}

const Radios = ({
  type,
  label,
  className,
  values,
  validate,
  buttonPressed,
  onChange,
  name,
  ...rest
}) => {
  return (
    <div className="radio-container">
      <FormLabel className="radio-label" component="legend">{label}*</FormLabel>
      <RadioGroup row className="radio-buttons" aria-label="gender" name={name} onChange={onChange}>
        {renderRadios(values)}
      </RadioGroup>
    </div>
  );
};



export default Radios;