import Joi from "joi-browser";
import React, { Component } from "react";
import Button from "./Button";
import Checkbox from './Checkbox';
import Dropdown from './Dropdown';
import Input from "./Input";
import Radios from './Radios';

export default class Form extends Component {
  state = {
    submitPressed: false,
    data: {},
    errors: {}
  };

  validate = () => {
    const options = {
      abortEarly: false
    };

    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) {
      return null;
    }

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    // console.log(errors);
    return errors ? errors : null;
  };

  validateProperty = ({ name, value }) => {
    const obj = {
      [name]: value
    };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e, onSubmit) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      return;
    }
    onSubmit(this.state.data);
  };

  deleteProperty = (obj, property) => {
    delete obj[property];
  };

  toggleSubmitFlag = submitFlag => {
    let submitPressed = !submitFlag;
    this.setState({ submitPressed });
  };

  validateConfirmPassword(
    password,
    confirmPassword,
    confirmPasswordName,
    errors
  ) {
    if (password !== confirmPassword) {
      const errorMessage = this.validateProperty({
        name: confirmPasswordName,
        value: confirmPassword
      });
      errors[confirmPasswordName] = errorMessage;
    } else {
      this.deleteProperty(errors, confirmPasswordName);
    }
  }

  handleChange = ({ currentTarget }) => {
    const errors = { ...this.state.errors };
    const { name, value } = currentTarget;
    const errorMessage = this.validateProperty(currentTarget);
    if (errorMessage) {
      errors[name] = errorMessage;
    } else {
      this.deleteProperty(errors, name);
    }
    const data = { ...this.state.data };
    data[name] = value;
    // if (name != "birthday" && name != "gender")
    localStorage.setItem(name, value);
    this.setState({ data, errors });
  };

  renderButton(label, className, type, buttonPressed = false, validate = true) {
    return (
      <Button
        type={type}
        validate={validate == true ? this.validate() : false}
        buttonPressed={buttonPressed}
        className={className}
        label={label}
        error={this.state.errors}
      />
    );
  }

  renderInput(
    name,
    label,
    placholder,
    disabled = false,
    type = "text",
    required = false,
    defaultValue = ""
  ) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        label={label}
        placeholder={placholder}
        disabled={disabled}
        type={type}
        required={required}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        defaultValue={defaultValue}
      />
    );
  }

  renderRadio(
    name,
    label,
    placeholder,
    values,
    disabled = false,
    required = false
  ) {
    const { data, errors } = this.state;
    return (
      <Radios
        name={name}
        label={label}
        placeholder={placeholder}
        values={values}
        disabled={disabled}
        required={required}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderCheckbox(
    name,
    label,
    placeholder,
    values,
    disabled = false,
    required = false,
    optionalHandler = null
  ) {
    const { data, errors } = this.state;
    return (
      <Checkbox
        name={name}
        label={label}
        placeholder={placeholder}
        values={values}
        disabled={disabled}
        required={required}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        optionalHandler={optionalHandler}
      />
    );
  }

  renderDropdown(
    name,
    label,
    placeholder,
    disabled = false,
    required = false,
    showRegion = false
  ) {
    const { data, errors } = this.state;
    return (
      <Dropdown
        name={name}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        showRegion={showRegion}
      />
    );
  }

}