import jwtDecode from 'jwt-decode';
import { TOKEN_HEADER } from '../../constants/headers';
import { BASE_API_ROUTE } from '../../constants/routes';
import { deleteCall, get, post } from './base';

export function getVideos() {
  return get(BASE_API_ROUTE + '/videos').then((res) => {
    return res;
  });
}

export function postVideo(data) {
  return post(BASE_API_ROUTE + '/video/create', data, {}, true).then((res) => {
    return res;
  });
}

export function deleteNews(newsId) {
  return deleteCall(BASE_API_ROUTE + '/news/' + newsId, {}).then((res) => {
    return res;
  });
}

export function signIn(data) {
  return post(BASE_API_ROUTE + '/auth/sign-in', {
    email: data.email,
    password: data.password,
  }).then((res) => {
    return res;
  });
}

export function signOut() {
  const jwt = localStorage.getItem(TOKEN_HEADER);
  return deleteCall(BASE_API_ROUTE + '/auth/sign-out', {
    headers: {
      [TOKEN_HEADER]: jwt,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    return res;
  });
}

export function setJwt(jwt) {
  localStorage.setItem(TOKEN_HEADER, jwt);
}

export function getJwt() {
  const ajtem = localStorage.getItem(TOKEN_HEADER);
  if (ajtem == 'null') return null;
  return ajtem;
}

export function getCurrentUser() {
  try {
    const jwt = getJwt();
    return jwtDecode(jwt);
  } catch (err) {
    return null;
  }
}
