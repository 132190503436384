import { TextField, withStyles } from "@material-ui/core";
import React from "react";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#2273c9',
    },
    '& .MuiInput-outline:after': {
      borderBottomColor: '#2273c9',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#2273c9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2273c9',
      },
    },
  },
})(TextField);


const renderInput = (name, type, error, label, required, { onChange, defaultValue }) => {
  return <CssTextField
    name={name}
    id={type == "date" ? "date" : "outlined-basic"}
    label={label}
    variant="outlined"
    type={type}
    format={"dd/mm/yyyy"}
    InputLabelProps={type == "date" && {
      shrink: true,
    }}
    required={required}
    fullWidth
    value={defaultValue || null}
    onChange={event => onChange(event)}
  />
}

const Input = ({
  name,
  label,
  placeholder,
  required,
  error,
  type,
  ...rest
}) => {
  // add error if error occured
  return (
    <div className="form-group">
      {renderInput(name, type, error, label, required, { ...rest })}
      {error ? error : ""}
    </div>

  );
};

export default Input;