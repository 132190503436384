import React, { Component } from 'react';
import SignInForm from '../components/SignInForm';
import { HOME_ROUTE } from '../constants/routes';
import { getJwt, setJwt, signIn } from '../services/http/authService';

export default class SignIn extends Component {
  state = {
    isSubmitting: false,
    error: {},
  };

  onSubmit = (data) => {
    this.setState({ isSubmitting: true, error: {} });
    signIn(data)
      .then((res) => this.onSignInSuccessful(res))
      .catch((err) => this.onSignInError(err));
  };

  onSignInSuccessful = (res) => {
    setJwt(res.result.token);
    this.setState({ isSubmitting: false });
    window.location = HOME_ROUTE;
  };

  onSignInError = (error) => {
    this.setState({ isSubmitting: false, error });
  };

  render() {
    if (getJwt()) {
      window.location = HOME_ROUTE;
    }

    const { isSubmitting, error } = this.state;
    return <SignInForm onSubmit={this.onSubmit} isSubmitting={isSubmitting} error={error} />;
  }
}
